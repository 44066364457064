<template>
  <block
    component-name="block-text-chapter"
    body-class="grid grid-cols-12 gap-ui"
  >
    <txt-chapter
      class="col-span-full phablet:col-start-2 phablet:col-end-12 laptop:col-start-3 laptop:col-end-11 extdesktop:col-start-4 extdesktop:col-end-10 w-full"
      :size="$attrs.config.size || 'regular'"
      :overline="$attrs.data.body.overline || undefined"
      :title="$attrs.data.body.title || undefined"
      :text="$attrs.data.body.text || undefined"
      :button="$attrs.data.body.button || undefined"
    />
  </block>
</template>
